var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    d-block\n    position-relative\n    bgi-size-cover\n    bgi-position-center\n    bgi-no-repeat\n    overflow-hidden\n    rounded\n  ",style:({
    'width': _vm.width,
    'height': _vm.height,
    'background-image': ("url(" + _vm.bgImg + ")")
  })},[(_vm.uploadActive)?_c('div',{staticClass:"position-absolute",style:({
      'top': '50%',
      'left': '50%',
      'margin-top': ("-" + (_vm.containerHeight / 4) + "px"),
      'margin-left': ("-" + (_vm.containerWidth / 4) + "px")
    })},[_c('RadialProgressbar',{attrs:{"diameter":_vm.containerWidth / 2,"completed-steps":_vm.progress,"total-steps":100,"stroke-width":4,"inner-stroke-color":"#eaeaea","animate-speed":100}},[_c('p',[_vm._v(" "+_vm._s(Math.round(_vm.progress))+" ")])])],1):_vm._e(),_c('b-form',{staticClass:"w-100 h-100",on:{"submit":function($event){$event.preventDefault();}}},[_c('label',{staticClass:"select-label w-100 h-100 d-flex align-items-center cursor-pointer justify-content-center",attrs:{"for":_vm.id}},[(_vm.bgImg !== '' && _vm.bgImg !== null)?_c('span',[(!_vm.uploadActive)?_c('i',{staticClass:"fal fa-retweet toggleme"}):_vm._e()]):_c('span',{staticClass:"\n          d-flex\n          flex-column\n          align-items-center\n          justify-content-center\n          text-warning\n          text-center\n          bg-light\n          w-100\n          h-100"},[(!_vm.uploadActive)?_c('i',{staticClass:"fal fa-exclamation-triangle font-size-xl d-block mb-2"}):_vm._e(),(!_vm.uploadActive)?_c('span',{staticClass:"font-size-xs"},[_vm._v(_vm._s(_vm.text))]):_vm._e()]),_c('input',{staticClass:"d-none",attrs:{"id":_vm.id,"type":"file"},on:{"change":function($event){return _vm.uploadImage($event)}}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }