<template>
  <div
    class="
      d-block
      position-relative
      bgi-size-cover
      bgi-position-center
      bgi-no-repeat
      overflow-hidden
      rounded
    "
    :style="{
      'width': width,
      'height': height,
      'background-image': `url(${ bgImg })`
    }"
  >
    <!--begin::radial-progress-circle-->
    <div
      v-if="uploadActive"
      class="position-absolute"
      :style="{
        'top': '50%',
        'left': '50%',
        'margin-top': `-${ containerHeight / 4 }px`,
        'margin-left': `-${ containerWidth / 4 }px`
      }"
    >
      <RadialProgressbar
        :diameter="containerWidth / 2"
        :completed-steps="progress"
        :total-steps="100"
        :stroke-width="4"
        inner-stroke-color="#eaeaea"
        :animate-speed="100"
      >
        <p>
          {{ Math.round(progress) }}
        </p>
      </RadialProgressbar>
    </div>
    <!--end::radial-progress-circle-->

    <!--begin::imageUploadForm-->
    <b-form
      class="w-100 h-100"
      @submit.prevent
    >
      <label
        :for="id"
        class="select-label w-100 h-100 d-flex align-items-center cursor-pointer justify-content-center"
      >
        <span v-if="bgImg !== '' && bgImg !== null">
          <i v-if="!uploadActive" class="fal fa-retweet toggleme"></i>
        </span>
        <span
          v-else
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            text-warning
            text-center
            bg-light
            w-100
            h-100"
          >
          <i v-if="!uploadActive" class="fal fa-exclamation-triangle font-size-xl d-block mb-2"></i>
          <span v-if="!uploadActive" class="font-size-xs">{{ text }}</span>
        </span>
        <input
          :id="id"
          class="d-none"
          type="file"
          @change="uploadImage($event)"
        />
      </label>
    </b-form>
    <!--end::imageUploadForm-->
  </div>
</template>

<script>
  import { ref, computed } from '@vue/composition-api'
  import useTextUtils from '@src/use/textUtils'
  import { trans, transChoice } from '@src/core/helpers/translate'
  import * as objectPath from 'object-path'
  import { GET_ALL } from '@src/store/auth'
  import { capitalize } from '@src/core/helpers/textUtils'
  import { TASKS } from '@src/store/tasks'

  export default {
    name: 'UploadImage',
    components: {
      RadialProgressbar: () => import('@src/components/core/RadialProgressbar')
    },
    props: {
      containerWidth: {
        type: [
          Number,
          String
        ],
        required: false,
        default: 100
      },
      containerHeight: {
        type: [
          Number,
          String
        ],
        required: false,
        default: 100
      },
      defaultImage: {
        type: String,
        required: false,
        default: ''
      },
      targetPath: {
        type: String,
        required: false,
        default: 'uploads'
      },
      dbCollection: {
        type: String,
        required: true
      },
      templateId: {
        type: [
          Boolean,
          String
        ],
        required: false,
        default: false
      },
      docId: {
        type: String,
        required: true
      },
      dbPath: {
        type: String,
        required: true
      },
      cloudFunction: {
        type: String,
        required: true
      },
      text: {
        type: String,
        required: false,
        default: trans('templates.edit.info.missingImage')
      }
    },
    setup (props, { root }) {
      const uploadActive = ref(false)
      const progress = ref(0)
      const bgImg = ref(JSON.parse(JSON.stringify(props.defaultImage)))

      const height = computed(() => (typeof props.containerHeight === 'number' ? `${ Math.round(props.containerHeight) }px` : props.containerHeight)) // eslint-disable-line max-len
      const width = computed(() => (typeof props.containerWidth === 'number' ? `${ Math.round(props.containerWidth) }px` : props.containerWidth)) // eslint-disable-line max-len

      const heightInt = computed(() => height.value.replace(/\D+/gu, ''))
      const widthInt = computed(() => width.value.replace(/\D+/gu, ''))

      const { randomString } = useTextUtils()

      const id = ref(randomString())

      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require

      const uploadImage = (e) => {
        console.log('Uploading image:', e)
        const imageData = e.target.files[0]
        uploadActive.value = true

        if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined) {
          const randomText = randomString()

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: objectPath.get(imageData, 'type', '')
          }

          const newFilename = `${randomText}.${imageData.name.split('.').pop()}`
          const fileOrgName = imageData.name
          const imageTargetPath = props.targetPath

          const uploadTask = storageRef
            .child(`${imageTargetPath}/${newFilename}`)
            .put(imageData, metadata)

          uploadTask.on('state_changed', (snapshot) => {
            progress.value = snapshot.bytesTransferred / snapshot.totalBytes * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
                break
              default:
                console.log('Something is running...')
                break
            }
          }, (error) => {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
              default:
                console.log('Something else was wrong...')
                break
            }
          }, async () => {
            const existingDocData = await fb.db.collection(props.dbCollection).doc(props.docId).get().then((doc) => {
              if (!doc.exists) return {}
              return doc.data()
            }).catch(() => ({}))

            const docImages = objectPath.get(existingDocData, props.dbPath, {})
            const nextImageId = !isNaN(parseInt(Object.keys(docImages).pop(), 10)) ? parseInt(Object.keys(docImages).pop(), 10) + 1 : 0 // eslint-disable-line max-len
            const authUser = root.$store.getters[GET_ALL]

            fb.db.collection(props.dbCollection).doc(props.docId).update({
              [`${ props.dbPath }.${ nextImageId }`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                uId: objectPath.get(authUser, 'user.uid', null),
                deleted: false
              }
            }).then(async () => {
              const updateImage = fb.functions.httpsCallable(props.cloudFunction)
              const funcData = {
                id: props.docId,
                collection: props.dbCollection,
                path: props.dbPath
              }

              if (props.templateId) {
                funcData.templateId = props.templateId
              }

              const result = await updateImage({
                data: funcData
              }).then((res) => res.data).catch((err) => ({
                success: false,
                errors: err
              }))

              if (!result.success) {
                console.log('ERRORS while uploading images:', result)
                root.$bvToast.toast(trans('templates.edit.info.image.change.error.desc', { err: result.errors }), {
                  title: capitalize(transChoice('global.error', 0)),
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 5000
                })
              } else {
                // Update the "oppgaver"-count
                root.$store.dispatch(TASKS.ACTIONS.FETCH_TASKS, { status: 0, limit: false }) // eslint-disable-line object-property-newline, max-len

                root.$bvToast.toast(trans('templates.edit.info.image.change.success.desc'), {
                  title: capitalize(transChoice('global.success', 0)),
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 5000
                })

                const imgPath = objectPath.get(result, 'result.image.path', '')
                const imgFile = objectPath.get(result, 'result.image.file', '')
                if (imgPath !== '' && imgFile !== '') {
                  bgImg.value = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }`
                }
              }

              uploadActive.value = false // end the radial-progress-bar
            }).catch((err) => {
              console.log('ERRORS while uploading images:', err)
              root.$bvToast.toast(trans('templates.edit.info.image.change.error.desc', { err }), {
                title: capitalize(transChoice('global.error', 0)),
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000
              })
            })
          })
        }
      }

      return {
        id,
        progress,
        uploadActive,
        trans,
        uploadImage,
        bgImg,
        width,
        widthInt,
        height,
        heightInt
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-label {
    i {
      color: #333;
      opacity: .8;
      font-weight: 600;
    }
    &:hover {
      i {
        opacity: 1;
      }
      background-color: rgba(196, 195, 195, 0.8);
    }
  }
  .radial-progress-inner {
    p {
      margin-bottom: 0;
      font-weight: 600;
      color: #555555;
    }
  }
</style>
