export default function useTextUtils () {
  /**
   * RandomTxt:
   * creates a random string based on length, chars and the start-text
   */
  const randomString = (pretxt = '', length = 16) => {
    let txt = pretxt
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < length; i++) {
      txt += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return txt
  }

  const randomFilename = (filename = '') => `${ randomString() }.${ filename.split('.').pop()}`

  return {
    randomString,
    randomFilename
  }
}
